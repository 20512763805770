import {
  AxoUrlQueryParams,
  formatDate,
  getBasicQuestionProps,
  getUrlQueryParam,
} from '@axo/deprecated/util/ui-components';
import {
  DEBT_REGISTRY_SESSION_KEYS_OLD as DEBT_REGISTRY_SESSION_KEYS,
  IDebtsResponse,
  debtRegistrySharingTimestampStorageOld as debtRegistrySharingTimestampStorage,
} from '@axo/form/feature/debt-registry';
import { createElement } from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';

import { getStoredRefinanceableDebts } from '../../../../components/ExtendedLoanOverview/context/ExtendedLoanOverviewContext/store/getStoredValues';
import { getLibConfig } from '../../../../configure';
import { DebtRegistryNO } from './DebtRegistryNO';
import getDebtRegistryLabels from './utils/getDebtRegistryLabels';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.createLocalizableString('serviceUnavailableMessage', this, false);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get serviceUnavailableMessage(): string {
    return this.getPropertyValue('serviceUnavailableMessage');
  }

  public set serviceUnavailableMessage(val: string) {
    this.setPropertyValue('serviceUnavailableMessage', val);
  }

  get locServiceUnavailableMessage(): LocalizableString {
    return this.getLocalizableString('serviceUnavailableMessage');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  constructor(props: unknown) {
    super(props);
    const debtManualInput = window.sessionStorage.getItem(
      DEBT_REGISTRY_SESSION_KEYS.debtManualInput
    );
    if (debtManualInput) {
      this.question.value = {
        debtDataShouldBeDisplayed: JSON.parse(debtManualInput),
        userHasSharedDebtData: false,
      };
    }
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    const magicToken = getUrlQueryParam(AxoUrlQueryParams.AXO_MAGIC_TOKEN);
    const provider = getUrlQueryParam(AxoUrlQueryParams.PROVIDER);
    const personalDebtOutstandingAmount =
      this.question.survey.getQuestionByName(
        'Debt/Personal/OutstandingAmount'
      )?.value;

    const isReturningCustomer = !!magicToken && !provider;

    const isReturningCustomerWithPersonalDebt =
      isReturningCustomer && personalDebtOutstandingAmount !== undefined;

    const applicationAxoToken = window.sessionStorage.getItem('axo.token');
    const applicationID = window.sessionStorage.getItem('axo.application-id');

    const assentlySrc = getLibConfig().ASSENTLY_URL || '';
    const endpoint = getLibConfig().API_URL || '';

    const storedRefinanceableDebts = getStoredRefinanceableDebts({});
    if (storedRefinanceableDebts) {
      this.question.value = {
        userHasSharedDebtData: true,
        useSessionStorage: true,
      };
    }

    const onFetchDebt = (debtResponse: IDebtsResponse) => {
      const totalDebtQuestion = this.question.survey.getQuestionByName(
        'Debt/Personal/OutstandingAmount'
      );

      totalDebtQuestion.value = debtResponse.debtTotal;

      this.question.value = {
        userHasSharedDebtData: true,
        data: debtResponse,
        debtDataShouldBeDisplayed: true,
        useSessionStorage: false,
      };
      if (!debtRegistrySharingTimestampStorage.get()) {
        debtRegistrySharingTimestampStorage.set(new Date());
      }

      if (debtResponse.savings) {
        const savedAmountQuestion = this.question.survey.getQuestionByName(
          'Ignore/SaveAmountNO'
        );
        savedAmountQuestion.value = Math.floor(debtResponse.savings);
      }
    };

    const onToggleManual = (manual: boolean) => {
      this.question.value = {
        ...(this.question.value || {}),
        debtDataShouldBeDisplayed: manual,
        userHasSharedDebtData: false,
      };
    };

    const debtRegistrySharingTimestamp =
      debtRegistrySharingTimestampStorage.get();

    const informationModalTextWithTimestamp = formatDate(
      debtRegistrySharingTimestamp,
      'Gjeldinformationen er delt / Usikret gjeld registeret i Gjeldsregisteret [dd].[MM].[yyyy], klokken [hh]:[mm].'
    );

    const debtRegistryLabels = getDebtRegistryLabels(
      informationModalTextWithTimestamp
    );

    const debtRegistryProps: React.ComponentProps<typeof DebtRegistryNO> = {
      errors: this.question.getAllErrors(),
      endpoint,
      onFetchDebt,
      onToggleManualMode: onToggleManual,
      customerJWT: applicationAxoToken ?? '',
      applicationID: applicationID ?? '',
      assentlySrc,
      ...debtRegistryLabels,
    };

    const shouldRender =
      !!applicationAxoToken &&
      !!applicationID &&
      !isReturningCustomerWithPersonalDebt;

    if (!shouldRender) return <></>;

    return (
      <div style={style}>
        <DebtRegistryNO
          serviceUnavailableMessage={this.question.serviceUnavailableMessage}
          {...debtRegistryProps}
        />
      </div>
    );
  }
}

export function registerDebtRegistryQuestion() {
  const questionType = 'Axo Debt Registry';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'serviceUnavailableMessage',
        category: 'general',
        type: 'string',
        serializationProperty: 'locServiceUnavailableMessage',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
