import { useApplicationCompleteStore } from '../useApplicationComplete.store';

export const storeApplicationData = (application: {
  applicationId?: string;
  personId?: string;
  token?: string;
}) => {
  const { setAuth } = useApplicationCompleteStore.getState();

  setAuth({ ...application });
};
