import { ReactNode } from 'react';
import styles from './InfoCard.module.scss';

type InfoCardProps = {
  state?: 'success';
  icon?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
};
export const InfoCard = ({
  icon,
  title,
  state = 'success',
  description,
  children,
}: InfoCardProps) => {
  const isHeaderVisible = !!(icon || title);
  const isDescriptionVisible = !!description;
  const isDividerVisible = isHeaderVisible && isDescriptionVisible;

  return (
    <div className={styles.infoCard} data-state={state}>
      {isHeaderVisible && (
        <div className={styles.header}>
          <span className={styles.icon}>{icon}</span>
          <span className={styles.title}>{title}</span>
        </div>
      )}
      {isDividerVisible && <div className={styles.divider} />}
      {isDescriptionVisible && (
        <div className={styles.description}>{description}</div>
      )}
      {children}
    </div>
  );
};
