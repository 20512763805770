import { MessageBox } from '@axo/deprecated/util/ui-components';
import { DebtRegistry, IDebtRegistry } from '@axo/form/feature/debt-registry';
import { TProviders } from '@axo/shared/auth/providers';
import { useFlags } from '@axo/shared/services/feature-flags';
import { MarketCountryCode } from '@axo/shared/types';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import DebtRegistryUnavailable from './DebtRegistryUnavailable';

const flags = {
  noDebtRegistryEnabled: 'noDebtRegistryEnabled',
  bankIDProvider: 'bankIDProvider',
};

type TDebtRegistryNO = IDebtRegistry & {
  errors: { text: string }[];
  serviceUnavailableMessage?: string;
};

export function DebtRegistryNO(props: TDebtRegistryNO): ReactElement {
  const { errors, onToggleManualMode, serviceUnavailableMessage } = props;

  const isInternalRedirect = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    return (
      searchParams.get('traffic') === 'internal' ||
      searchParams.get('utm_campaign') === 'ace'
    );
  }, []);

  const {
    noDebtRegistryEnabled: isDebtRegistryAvailable = true,
    bankIDProvider,
  } = useFlags(flags);

  const loginButtonRef = useRef<HTMLButtonElement>(null);

  const errorMessage =
    'Du må logge inn og dele din gjeldsregister-informasjon for å fortsette.';

  const hasErrors =
    errors.length > 0 &&
    errors.at(0)?.text ===
      'Du må fylle ut informasjon om din gjeld. Om du ikke vil dele din gjeld fra gjeldsregisteret, velg «Fyll ut manuelt» for å fortsette.';

  // TODO temporary for experiment, needs refactoring, use Button component interface + context in MessageBoxBankID
  if (hasErrors) {
    //get user attention to login button by adding 'wobble' animation to it
    if (loginButtonRef?.current) {
      loginButtonRef.current.dataset.animation = 'wobble';
      loginButtonRef.current.onanimationend = () => {
        loginButtonRef?.current?.toggleAttribute('data-animation');
      };
    }
  }

  useEffect(() => {
    if (!isDebtRegistryAvailable || isInternalRedirect)
      onToggleManualMode?.(true);
  }, [isDebtRegistryAvailable, onToggleManualMode, isInternalRedirect]);

  const debtRegistryProviders = useMemo(() => {
    if (bankIDProvider === TProviders.Criipto) {
      return {
        [TProviders.Criipto]: {
          country: MarketCountryCode.Norway,
          label: 'Logg inn med BankID',
        },
        [TProviders.Vipps]: {},
      };
    }

    return {
      [TProviders.BankID]: {
        serviceSourceUrl: props.assentlySrc,
      },
      [TProviders.Vipps]: {},
    };
  }, [bankIDProvider, props.assentlySrc]);

  if (!isDebtRegistryAvailable)
    return <DebtRegistryUnavailable message={serviceUnavailableMessage} />;

  return (
    <>
      <DebtRegistry
        applicationID={props.applicationID}
        axoApiUrl={props.endpoint}
        customerJWT={props.customerJWT}
        providers={debtRegistryProviders}
        onFetchDebt={props.onFetchDebt}
        allowManualInput={false}
        isSellingPointsVisible={false}
      />

      {hasErrors && (
        <MessageBox
          iconVisible={true}
          marginTop={true}
          variety="error"
          text={errorMessage}
        />
      )}
    </>
  );
}
