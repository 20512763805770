import { IDebt, LoansModalUI } from '@axo/form/feature/debt-registry';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { InfoCard } from '@axo/ui-feature/components/InfoCard/InfoCard';
import { useCallback, useMemo, useState } from 'react';
import getDebtRegistryLabels from '../../no/utils/getDebtRegistryLabels';
import styles from './SaveAmount.module.scss';
import { labels } from './utils/labels';

type SaveAmountProps = {
  type: 'info' | 'savings';
  debts?: IDebt[];
  totalDebt?: number;
};

export const SaveAmount = ({ type, debts, totalDebt }: SaveAmountProps) => {
  const [isDebtsInfoModalVisible, setIsDebtsInfoModalVisible] = useState(false);

  const openDebtsInfoModal = useCallback(
    () => setIsDebtsInfoModalVisible(true),
    []
  );

  const closeDebtsInfoModal = useCallback(
    () => setIsDebtsInfoModalVisible(false),
    []
  );

  const ButtonOpenDebtsInfoModal = useMemo(
    () =>
      debts?.length ? (
        <Button
          variant="tertiary"
          onClick={openDebtsInfoModal}
          className={styles.debtsInfoButton}
        >
          <div className={styles.debtsInfoButtonContent}>
            <Icon
              name="external-link"
              size="xs"
              className={styles.debtsInfoButtonIcon}
            />
            <span>Se din eksisterende gjeld</span>
          </div>
        </Button>
      ) : null,
    [openDebtsInfoModal, debts]
  );

  const infoCardProps = useMemo(() => {
    if (type === 'info') {
      return {
        title: labels.title.noDebt,
        description: labels.description.noDebt,
        icon: <Icon name="info" />,
        children: ButtonOpenDebtsInfoModal,
      };
    }

    if (type === 'savings') {
      return {
        title: labels.title.refinanceableDebts,
        description: labels.description.refinanceableDebts,
        icon: <Icon name="piggy-bank-solid" />,
        children: ButtonOpenDebtsInfoModal,
      };
    }
  }, [type, ButtonOpenDebtsInfoModal]);

  if (!type) return null;

  return (
    <>
      <InfoCard {...infoCardProps} />
      {isDebtsInfoModalVisible && debts?.length && (
        <LoansModalUI
          debts={debts}
          loansModalDebtEntriesTexts={
            getDebtRegistryLabels('')['loansModalProps'][
              'loansModalDebtEntriesTexts'
            ]
          }
          loansModalTexts={
            getDebtRegistryLabels('')['loansModalProps']['loansModalTexts']
          }
          onButtonClick={closeDebtsInfoModal}
          onCloseButtonClick={closeDebtsInfoModal}
          totalDebt={totalDebt ?? 0}
        />
      )}
    </>
  );
};
