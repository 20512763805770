import { ComponentProps, ReactNode, useCallback } from 'react';

import { classNames } from '@axo/shared/util/dom';
import { getFormattedAmountUI } from '@axo/shared/util/formatters';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon } from '@axo/ui-core/components/Icon';
import { useExtendedLoanOverviewContext } from '../../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import { TBlockType } from '../../ExtendedLoanOverview.types';
import Detail from '../Detail/Detail';
import Slider from '../Slider/Slider';
import SteppedNumberInput from '../SteppedNumberInput/SteppedNumberInput';
import styles from './LoanOverviewBlock.module.scss';

export type TLoanOverviewBlockProps = {
  type?: TBlockType;
  title?: ReactNode;
  isEditable?: boolean;
  value?: number;
  valueAsNode?: ReactNode;
  labelSuffix?: string;
  editType?: 'slider' | 'steppedNumberInput' | 'toggle';
  details?: Omit<ComponentProps<typeof Detail>, 'onChangeComplete'>[];
  minValue?: number;
  maxValue?: number;
  step?: number;
  isEditMode?: boolean;
  warningMessage?: ReactNode;
  theme?: 'default' | 'blue';
  isWarning?: boolean;
  sliderConfig?: Partial<ComponentProps<typeof Slider>>;
  isInfoButtonVisible?: boolean;
  description?: ReactNode;
  className?: string;
  isTotalAmountVisible?: boolean;
  isPermanentEdit?: boolean;
  onChange?: (newValue: number) => void;
  onEnableEditModeClick?: (mode: TBlockType | undefined) => void;
  onInfoButtonClick?: () => void;
};

const LoanOverviewBlock = ({
  type,
  title = '',
  value = undefined,
  valueAsNode,
  isEditable = true,
  labelSuffix = '',
  editType = 'slider',
  minValue = 0,
  maxValue = 10000,
  step = 500,
  isEditMode = false,
  warningMessage,
  details,
  theme = 'default',
  isWarning,
  sliderConfig,
  isInfoButtonVisible,
  description,
  className,
  isTotalAmountVisible = true,
  isPermanentEdit = false,
  onInfoButtonClick,
  onEnableEditModeClick,
  onChange,
}: TLoanOverviewBlockProps) => {
  const { currency } = useExtendedLoanOverviewContext();

  const handleEditModeClick = useCallback(() => {
    onEnableEditModeClick?.(type);
  }, [onEnableEditModeClick, type]);

  const handleCloseEditMode = useCallback(() => {
    onEnableEditModeClick?.(undefined);
  }, [onEnableEditModeClick]);

  const handleOnChange = useCallback(
    (newValue: number) => {
      onChange?.(newValue);
    },
    [onChange]
  );

  const _isEditMode = isPermanentEdit || isEditMode;

  const valueFormatter = useCallback(
    (value: number) => (
      <div className={styles['stepped-number-input-value']}>
        <span className={styles.title}>{title}</span>
        <span
          className={classNames(
            styles.value,
            isWarning && styles['value--warning']
          )}
        >{`${value} ${labelSuffix}`}</span>
      </div>
    ),
    [labelSuffix, title, isWarning]
  );

  const hasDetails = !!details?.length;

  const isEditButtonVisible = isEditable && !_isEditMode && !isPermanentEdit;
  const isConfirmButtonVisible = isEditable && _isEditMode && !isPermanentEdit;

  const showSlider = !hasDetails && editType === 'slider' && _isEditMode;
  const showSteppedNumberInput =
    !hasDetails && editType === 'steppedNumberInput' && _isEditMode;

  return (
    <div
      className={classNames(
        styles.loanOverviewBlock,
        theme === 'blue' && styles['loanOverviewBlock--blue'],
        className
      )}
    >
      <div className={styles.titleContainer}>
        <div className={styles.left}>
          {<div className={styles.title}>{title}</div>}
          {isInfoButtonVisible && (
            <div onClick={onInfoButtonClick}>
              <Icon name="info-solid" size="s" className={styles.icon} />
            </div>
          )}
        </div>
        {isTotalAmountVisible && (
          <div className={styles.right}>
            {value !== undefined && (
              <span className={styles.value}>{`${getFormattedAmountUI(
                value
              )} ${labelSuffix}`}</span>
            )}

            {valueAsNode}

            {isEditButtonVisible && (
              <div onClick={handleEditModeClick}>
                <Icon
                  name="pencil-simple-line"
                  size="xs"
                  className={styles.icon}
                />
              </div>
            )}
            {isConfirmButtonVisible && (
              <div onClick={handleCloseEditMode}>
                <Icon
                  name="check-fat-solid"
                  size="xs"
                  className={styles.icon}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {showSteppedNumberInput && value !== undefined && (
        <SteppedNumberInput
          value={value}
          onChange={handleOnChange}
          minValue={minValue}
          maxValue={maxValue}
          isWarning={isWarning}
          valueFormatter={valueFormatter}
        />
      )}
      {description}
      {warningMessage}
      {showSlider && value !== undefined && (
        <Slider
          value={value}
          max={maxValue}
          min={minValue}
          onChange={handleOnChange}
          step={step}
          currency={currency}
          className={styles.slider}
          {...sliderConfig}
        />
      )}
      {hasDetails && (
        <div className={styles.details}>
          {details?.map((detail, index) => (
            <Detail
              key={detail.label.concat(index.toString())}
              {...detail}
              isEditMode={_isEditMode}
              editType={editType}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LoanOverviewBlock;
