import { TRefinanceableDebt } from '../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext.types';

type TGetLoanAllocationParams = {
  maxLoanAmount: number;
  currentCashAmount: number;
  priority: string[];
  currentRefinanceableDebts: TRefinanceableDebt[];
  isUpSell?: boolean;
  isToggle?: boolean;
};

export const getLoanAllocation = ({
  currentCashAmount,
  maxLoanAmount,
  priority,
  currentRefinanceableDebts,
  isUpSell = true,
  isToggle = false,
}: TGetLoanAllocationParams) => {
  const refinanceableDebtsAsKeyValue: { [key: string]: number } = {};

  currentRefinanceableDebts.forEach((debt) => {
    refinanceableDebtsAsKeyValue[debt.key] = debt.value;
  });

  const totalRefinanceableDebtsValue = currentRefinanceableDebts.reduce(
    (acc, debt) => {
      if (!debt.isChecked) return acc;

      if (isToggle && maxLoanAmount && debt.value > maxLoanAmount) return acc;

      return acc + debt.value;
    },
    0
  );

  const allocation: Record<string, number> = {
    cash: isUpSell
      ? currentCashAmount
      : Math.max(currentCashAmount - totalRefinanceableDebtsValue, 0),
    ...refinanceableDebtsAsKeyValue,
  };

  const totalLoanValue = priority.reduce((acc, key) => {
    // Checking if it is of type refinanceable debt
    const refinanceableDebt = currentRefinanceableDebts.find(
      (debt) => debt.key === key
    );

    // If it is not refinanceable debt, then just add the value to the accumulator
    if (!refinanceableDebt)
      return allocation[key] ? acc + allocation[key] : acc;

    // If it is refinanceable debt, then add the value only if it is checked
    return refinanceableDebt.isChecked ? acc + allocation[key] : acc;
  }, 0);

  if (totalLoanValue <= maxLoanAmount) {
    return allocation;
  }

  const newAllocation = priority.reduce((acc, key) => {
    const currentDebtAllocated = Object.values(acc).reduce(
      (acc, value) => acc + value,
      0
    );

    if (currentDebtAllocated >= maxLoanAmount) {
      return { ...acc, [key]: 0 };
    }

    if (currentDebtAllocated + (allocation[key] ?? 0) <= maxLoanAmount) {
      return { ...acc, [key]: allocation[key] ?? 0 };
    }

    return {
      ...acc,
      [key]:
        isToggle && key !== 'cash' ? 0 : maxLoanAmount - currentDebtAllocated,
    };
  }, {} as Record<string, number>);

  return newAllocation;
};
