import { Icons } from '@axo/shared/ui/atoms';
import { getFormattedAmountUI } from '@axo/shared/util/formatters';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Button } from '@axo/ui-core/components/Button';
import clsx from 'clsx';
import { useState } from 'react';
import { i18n } from './i18n.ts';
import styles from './LoanDurationSelector.module.scss';

export interface ILoanDurationSelector {
  country: 'no' | 'se' | 'dk' | 'fi';
  loanAmountPerMonth: number;
  currentLoanDuration: number;
  onDurationChange: (loanDuration: number) => void;
  maxDuration: number;
  minDuration: number;
  recommended: boolean;
}

/**
 * @todo align with Figma, move to @axo/ui-feature and remove dependencies on legacy
 * https://www.figma.com/design/RiQZwo3UxOvzNa7P2Fqgi2/Design-system-(WIP)?node-id=4211-834&m=dev
 * @deprecated
 */

export const LoanDurationSelector = ({
  country,
  loanAmountPerMonth,
  currentLoanDuration,
  onDurationChange,
  maxDuration,
  minDuration,
  recommended = false,
}: ILoanDurationSelector) => {
  const [loanDuration, setLoanDuration] = useState(currentLoanDuration);

  if (currentLoanDuration !== loanDuration) {
    setLoanDuration(currentLoanDuration);
  }

  const increaseDuration = () => {
    if (loanDuration === maxDuration) return;

    setLoanDuration(loanDuration + 1);
    onDurationChange(loanDuration + 1);
  };

  const decreaseDuration = () => {
    if (loanDuration === minDuration) return;

    setLoanDuration(loanDuration - 1);
    onDurationChange(loanDuration - 1);
  };

  return (
    <div className={styles.loanDurationSelector}>
      <div className={styles.column}>
        <p
          className={clsx(styles.columnLabel, styles.monthlyInstallment)}
          id={styles.monthlyInstallment}
        >
          {i18n.monthlyInstallment[country]}
        </p>

        <div className={styles.valueHolder}>
          <div className={styles.value} id={styles.monthlyInstallmentValue}>
            <span id={styles.monthlyInstallmentInline}>
              {i18n.monthlyInstallment[country]}
            </span>
            {getFormattedAmountUI(loanAmountPerMonth)} {i18n.currency[country]}
          </div>
        </div>
      </div>

      <div className={styles.column}>
        <p className={styles.columnLabel}>{i18n.loanDuration[country]}</p>

        <div
          className={styles.valueHolder}
          id={styles.loanDuration}
          data-testid="loan-duration-input"
        >
          <Button
            id={styles.decreaseButton}
            variant="tertiary"
            disabled={loanDuration === minDuration}
            onClick={() => decreaseDuration()}
          >
            <Icons.Minus size="xs" />
          </Button>

          <div className={styles.value}>
            <div id={styles.loanDurationValueHolder}>
              <span id={styles.durationLabel}>
                {' ' + i18n.loanDuration[country]}
              </span>
              {loanDuration} {i18n.year[country]}
            </div>
            {recommended && (
              <div className={styles.recommended}>
                {i18n.recommended[country]}
              </div>
            )}
          </div>

          <Button
            id={styles.increaseButton}
            variant="tertiary"
            disabled={loanDuration === maxDuration}
            onClick={() => increaseDuration()}
          >
            <Icons.Plus size="xs" />
          </Button>
        </div>
      </div>
    </div>
  );
};
