import { useEffect, useState } from 'react';
import { ITrustpilotJSONType } from '../../../models/trustpilot.model';
import TrustpilotWidget from './trustpilot-widget';

type TrustpilotProps = {
  trustpilot_link: string;
  version?:
    | 'compact'
    | 'extended'
    | 'extended-inline'
    | 'compact-score'
    | 'compact-score-square';
  countryCode: 'no' | 'se' | 'fi' | 'dk';
  withShadow?: boolean;
};

export const FormV1Trustpilot = (props: TrustpilotProps) => {
  const { trustpilot_link } = props;

  const json = useData<ITrustpilotJSONType>(trustpilot_link);

  if (!json) return null;

  return <TrustpilotWidget json={json} {...props} />;
};

function useData<T>(url: string) {
  const [data, setData] = useState<T>();

  useEffect(() => {
    let ignore = false;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (!ignore && !!json.starsString) {
          setData(json);
        }
      });
    return () => {
      ignore = true;
    };
  }, [url]);

  return data;
}
