import { loan_application } from '@axo/shared/data-access/types';

/**
 * @param baseUrl
 * @param token
 * @param loanApplicationId
 * @param finalState - Will be logged to the console on the backend. Can be anything json serializable.
 */
export async function patchApplicationComplete(
  baseUrl: string,
  token: string,
  loanApplicationId: string,
  finalState: unknown
): Promise<loan_application.StepComplete> {
  const url = new URL(
    `/loan-application/${loanApplicationId}/application-complete`,
    baseUrl
  );

  const response = await fetch(url, {
    method: 'PATCH',
    mode: 'cors',
    // Body is ignored by the backend. Maybe it was send as a way to know the final state of the frontend?
    body: JSON.stringify(finalState),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const responseJson = await response.json();

  if (response.ok) return responseJson;

  const completeErrorMessage = `Error fetching ${response.url}: ${
    response.status
  } ${response.statusText} ${responseJson.error || responseJson.message}`;

  throw new Error(completeErrorMessage);
}
