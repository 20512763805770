import { clsx } from 'clsx';
import { CSSProperties, ReactNode, Ref } from 'react';
import styles from './sectionLayout.module.scss';

export const layoutVariants = ['adaptive', 'fluid', 'responsive'] as const;
export const layoutContentVariants = ['unset', 'flex', 'grid'] as const;
export const gaps = ['none', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl'] as const;
export const contentTypes = ['default', 'article', 'app'] as const;

export type SectionLayoutProps = HTMLDataAttributes & {
  children: ReactNode;
  /**
   * - adaptive: breakpoints large, medium, mobile, small
   * - fluid: responsive till breakpoint large, then fixed content width
   * - responsive: 100% viewport width (use for full width components)
   * @default adaptive
   */
  layout?: (typeof layoutVariants)[number];
  className?: string;
  gap?: (typeof gaps)[number];
  type?: (typeof contentTypes)[number];
  as?: 'section' | 'div';
  style?: CSSProperties;
  _ref?: Ref<HTMLDivElement> | Ref<HTMLElement>;
};

const SectionLayoutRoot = ({
  children,
  layout = 'adaptive',
  className,
  gap,
  type = 'default',
  as: Element = 'section',
  _ref,
  ...props
}: SectionLayoutProps) => {
  const ref = _ref as Ref<HTMLDivElement & HTMLElement>;

  return (
    <Element
      className={clsx(styles.sectionLayout, className)}
      data-layout={layout}
      data-gap={!gap || gap === 'none' ? undefined : gap}
      data-content-type={type}
      ref={ref}
      {...props}
    >
      {children}
    </Element>
  );
};

type SectionLayoutBackgroundProps = HTMLDataAttributes & {
  children?: ReactNode;
  className?: string;
};

/**
 * full width/height for background images, etc.
 *
 * note:
 * - `overflow: hidden` up to child elements
 */
const SectionLayoutBackground = ({
  children,
  className,
  ...props
}: SectionLayoutBackgroundProps) => (
  <div className={clsx(styles.sectionLayout__background, className)} {...props}>
    {children}
  </div>
);

type SectionLayoutContentProps = HTMLDataAttributes & {
  children: ReactNode;
  layout?: (typeof layoutContentVariants)[number];
  className?: string;
  style?: CSSProperties;
};

const SectionLayoutContent = ({
  children,
  layout = 'flex',
  className,
  ...props
}: SectionLayoutContentProps) => {
  return (
    <div
      className={clsx(styles.sectionLayout__content, className)}
      {...(layout !== 'unset' && { 'data-layout': layout })}
      {...props}
    >
      {children}
    </div>
  );
};

type SectionLayoutAsideProps = {
  children: ReactNode;
  className?: string;
  position?: 'left' | 'right';
  order?: 'top' | 'bottom';
};

const SectionLayoutAside = ({
  children,
  className,
  /** horizontal position */
  position = 'right',
  /** vertical order on mobile */
  order = 'top',
}: SectionLayoutAsideProps) => (
  <div
    className={clsx(styles.sectionLayout__aside, className)}
    data-position={position}
    data-order={order}
  >
    {children}
  </div>
);

export const SectionLayout = Object.assign(SectionLayoutRoot, {
  Background: SectionLayoutBackground,
  Content: SectionLayoutContent,
  Aside: SectionLayoutAside,
});
