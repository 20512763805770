import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  getStoredCashAmount,
  getStoredRefinanceableDebts,
} from '../store/getStoredValues';

type TGetInitialValues = {
  isUpSell: boolean;
  appliedAmount: number;
  refinanceableDebts: TRefinanceableDebt[];
  loanApplicationID?: string;
  maxLoanAmount?: number;
  isToggle?: boolean;
};

export const getInitialValues = ({
  isUpSell,
  appliedAmount,
  refinanceableDebts,
  loanApplicationID,
  maxLoanAmount,
  isToggle = false,
}: TGetInitialValues) => {
  const storedRefinanceableDebts = getStoredRefinanceableDebts({
    refinanceableDebts,
  });

  const _refinanceableDebts = refinanceableDebts.map((debt) => {
    const storedDebt = storedRefinanceableDebts?.find(
      (storedDebt) => storedDebt.key === debt.key
    );

    return storedDebt ?? debt;
  });

  const storedCashAmountValue = getStoredCashAmount({ loanApplicationID });

  const totalDebt = _refinanceableDebts.reduce((acc, { value, isChecked }) => {
    if (!isChecked) return acc;

    if (isToggle && maxLoanAmount && value > maxLoanAmount) return acc;

    return acc + value;
  }, 0);

  const cashAmount =
    storedCashAmountValue ??
    (isUpSell ? appliedAmount : Math.max(appliedAmount - totalDebt, 0));

  return {
    refinanceableDebts: _refinanceableDebts,
    cashAmount,
  };
};
