import {
  AxoFormV1,
  loadFormVariant,
  loadMarket,
} from '@axo/form/feature/axo-form-v1';
import { useSetUtmParametersFromAffiliateTrackingLinkOnMount } from '@axo/form/util/white-labels';
import { Stack } from '@axo/ui-core/components/layout/item';
import { ThemeProvider } from '@axo/ui-core/themes';
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { sentryConfig } from './sentry-config';

Sentry.init(sentryConfig);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  useSetUtmParametersFromAffiliateTrackingLinkOnMount({
    integrationUrl: import.meta.env.VITE_INTEGRATION_URL,
  });

  const market = loadMarket();
  const variant = loadFormVariant();

  return (
    <SentryRoutes>
      <Route
        path="*"
        element={
          <ThemeProvider theme="axo-finans">
            <Stack>
              <AxoFormV1 market={market} variant={variant} />
            </Stack>
          </ThemeProvider>
        }
      />
    </SentryRoutes>
  );
};

export default App;
