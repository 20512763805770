import { isEqual } from 'lodash';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/vanilla/shallow';

import type {
  ApplicationCompleteStore,
  ApplicationCompleteStoreState,
  AuthData,
  DestinationProduct,
} from './application-complete.types';

const initialState: ApplicationCompleteStoreState = {
  apiUrl: null,
  auth: null,
  isEligible: false,
  context: null,
  destinations: null,
  destination: {
    type: 'direct',
    product: 'mypage',
  },
};

const useApplicationCompleteStore = create<ApplicationCompleteStore>()(
  subscribeWithSelector(
    devtools(
      (set, get) => ({
        ...initialState,

        setApiUrl: (apiUrl: string) => {
          const current = get().apiUrl;
          if (current !== apiUrl) set({ apiUrl }, undefined, 'setApiUrl');
        },
        setContext: (
          context: NonNullable<ApplicationCompleteStoreState['context']>
        ) => {
          const current = get().context;
          if (!shallow(current, context))
            set({ context: { ...context } }, undefined, 'setContext');
        },
        setDestinations: (
          destinations: NonNullable<
            ApplicationCompleteStoreState['destinations']
          >
        ) => {
          const current = get().destinations;
          if (!shallow(current, destinations))
            set(
              { destinations: { ...destinations } },
              undefined,
              'setDestinations'
            );
        },
        setDestination: (
          destination: Partial<ApplicationCompleteStoreState['destination']>
        ) =>
          set(
            (state) => ({
              destination: {
                ...state.destination,
                ...destination,
              },
            }),
            undefined,
            'setDestination'
          ),
        setAuth: (auth: AuthData) => {
          const current = get().auth;
          if (!isEqual(current, auth)) {
            set(
              {
                auth: {
                  ...current,
                  ...auth,
                },
              },
              undefined,
              'setAuth'
            );
          }
        },
        setIsEligible: (isEligible: boolean) => {
          const current = get().destination;
          const updatedDestination = {
            ...current,
            product: (isEligible
              ? 'insurance'
              : 'mypage') as DestinationProduct,
          };
          set(
            {
              isEligible,
              destination: {
                ...updatedDestination,
              },
            },
            undefined,
            'setIsEligible'
          );
        },
      }),
      { name: 'application-complete-store' }
    )
  )
);

export { useApplicationCompleteStore };
