/* eslint-disable @nx/enforce-module-boundaries */
import { DataSharingStatus, MarketCountryCode } from '@axo/shared/types';
import { SectionLayout } from '@axo/ui-core/components/layout/section';
import {
  FormAsideSkeleton,
  FormSkeleton,
} from '@axo/ui-feature/features/SkeletonPreset';
import {
  createContext,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { AxoFormOptions, useAxoForm } from '../../hooks/useAxoForm';
import { Step } from '../../types';
import { AxoFormContextOptions } from './AxoForm.types';

export type AxoFormContextData<OptionsType extends Record<string, unknown>> = {
  baseUrl: string;
  legacyUrl?: string;
  assentlyUrl?: string;
  axoFormData: OptionsType;
  step: number;
  steps: Step[];
  onChange: (data: OptionsType) => Promise<void>;
  marketCountry: MarketCountryCode;
  onInitiated: () => Promise<void>;
  onPreSubmit: (data: OptionsType) => Promise<void>;
  onPrevStep: () => void;
  onSubmit: (data: OptionsType, isPre?: boolean) => Promise<void>;
  StepComponent: () => ReactElement;
  reloadSyncData: () => Promise<void>;
  dataSharingStatus: DataSharingStatus;
  formRef?: RefObject<HTMLFormElement>;
  numberOfCompletedSteps: number;
};

const FormDataContext = createContext({});

type FormContextProviderProps<OptionsType extends Record<string, unknown>> = {
  children: ReactNode;
  baseUrl: string;
  legacyUrl?: string;
  assentlyUrl?: string;
  steps: Step[];
  isPre?: boolean;
  options: AxoFormContextOptions<OptionsType>;
  marketCountry: MarketCountryCode;
  dataSharingStatus?: DataSharingStatus;
  formRef?: RefObject<HTMLFormElement>;
  configureSteps?: (data: OptionsType) => void;
} & Pick<
  AxoFormOptions<OptionsType>,
  'redirectUrl' | 'addSearchParamsToRedirectUrl'
>;

const AxoFormContextProvider = <OptionsType extends Record<string, unknown>>({
  children,
  baseUrl,
  legacyUrl,
  redirectUrl,
  assentlyUrl,
  steps,
  options,
  isPre = false,
  marketCountry,
  dataSharingStatus,
  formRef,
  configureSteps,
  addSearchParamsToRedirectUrl,
}: FormContextProviderProps<OptionsType>) => {
  const { product, mutators, getTrackingData, initialSnapshotStepName } =
    options;

  const {
    axoFormData,
    initialized,
    syncData,
    step,
    onInitiated,
    setStep,
    StepComponent,
    submitStep,
    submitApplication,
    reloadSyncData,
    numberOfCompletedSteps,
  } = useAxoForm<OptionsType>({
    product,
    formRef,
    initialSnapshotStepName,
    baseUrl,
    redirectUrl,
    steps,
    mutators,
    getTrackingData,
    marketCountry: marketCountry,
    isPre,
    configureSteps,
    addSearchParamsToRedirectUrl,
  });

  const onChange = useCallback(
    (data: OptionsType) => {
      syncData(data);
    },
    [syncData]
  );

  const onPreSubmit = useCallback(
    async (data: OptionsType) => {
      await submitStep(data, true);

      if (redirectUrl) {
        window.location.href =
          typeof redirectUrl === 'function' ? redirectUrl() : redirectUrl;
      }
    },
    [submitStep, redirectUrl]
  );

  const onSubmit = useCallback(
    (data: OptionsType) => {
      if (step < steps.length) {
        return submitStep(data);
      }

      submitApplication(data);
    },
    [step, steps, submitStep, submitApplication]
  );

  const onPrevStep = useCallback(() => {
    setStep(step - 1);
  }, [step, setStep]);

  const formDataContextValue = useMemo(
    () => ({
      formRef,
      baseUrl,
      legacyUrl,
      assentlyUrl,
      axoFormData,
      step,
      steps,
      onChange,
      onInitiated,
      onPreSubmit,
      onPrevStep,
      onSubmit,
      StepComponent,
      dataSharingStatus,
      reloadSyncData,
      numberOfCompletedSteps,
    }),
    [
      formRef,
      baseUrl,
      legacyUrl,
      assentlyUrl,
      axoFormData,
      step,
      steps,
      onChange,
      onInitiated,
      onPreSubmit,
      onPrevStep,
      onSubmit,
      StepComponent,
      dataSharingStatus,
      reloadSyncData,
      numberOfCompletedSteps,
    ]
  );

  // FIXME no view components in context + skeleton loader
  if (!isPre && !initialized) {
    return (
      <SectionLayout layout={'fluid'} type={'app'}>
        <SectionLayout.Content>
          <FormSkeleton />
        </SectionLayout.Content>

        <SectionLayout.Aside position="right" order="bottom">
          <FormAsideSkeleton />
        </SectionLayout.Aside>
      </SectionLayout>
    );
  }

  return (
    <FormDataContext.Provider value={formDataContextValue}>
      {children}
    </FormDataContext.Provider>
  );
};

const useAxoFormContext = <T extends Record<string, unknown>>() => {
  const context = useContext(FormDataContext) as AxoFormContextData<T>;

  if (!context) {
    throw new Error(
      'useAxoFormContext must be used within a AxoFormContextProvider'
    );
  }

  return context;
};

export { AxoFormContextProvider, useAxoFormContext };
