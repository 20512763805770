import { storeCrossDomainAuth } from '@axo/shared/auth/crossdomain/store/store-cross-domain-auth';
import { storeApplicationData } from '@axo/site/state/ApplicationComplete/actions/store-application-data';
import { captureException } from '@sentry/browser';
import { useEffect, useState } from 'react';
import { SurveyModel } from 'survey-core';
import client from '../backend-sync/client';
import reloadState from '../backend-sync/reloadState';
import {
  dispatch,
  getFormState,
  IFormState,
  subscribe,
  unsubscribe,
} from '../store';
import injectData from '../survey/injectData';
import {
  IPersistanceValues,
  PersistanceValuesSchema,
} from '../types/IPersistanceValues';
import { Observer } from '../utils/Subject';

enum SessionStorageKeys {
  LOAN_APPLICATION_ID = 'axo.application-id',
  TOKEN = 'axo.token',
}

export const clearReloadData = () => {
  sessionStorage.removeItem(SessionStorageKeys.TOKEN);
  sessionStorage.removeItem(SessionStorageKeys.LOAN_APPLICATION_ID);
};

export const useReloadFromBackendOnInit = (survey: SurveyModel | undefined) => {
  useSaveIdsAndJWTWhenUpdated();
  useReloadAndInjectDataToSurvey(survey);
};

const useSaveIdsAndJWTWhenUpdated = () => {
  const { customer, loanApplication } = getFormState();

  useEffect(() => {
    if (!customer?.JWT || !loanApplication?.ID) return;

    sessionStorage.setItem(SessionStorageKeys.TOKEN, customer.JWT);
    sessionStorage.setItem(
      SessionStorageKeys.LOAN_APPLICATION_ID,
      loanApplication.ID
    );

    storeCrossDomainAuth({
      applicationId: loanApplication.ID,
      token: customer.JWT,
    });
  }, [customer?.JWT, loanApplication?.ID]);

  useEffect(() => {
    const observer: Observer<IFormState> = async (value) => {
      const { customer, loanApplication } = value;

      if (customer?.JWT || loanApplication?.ID) {
        storeApplicationData({
          token: customer?.JWT,
          applicationId: loanApplication?.ID,
        });
      }
    };

    subscribe(observer);

    return () => unsubscribe(observer);
  }, []);
};

const useReloadAndInjectDataToSurvey = (survey: SurveyModel | undefined) => {
  const { customer, loanApplication } = getFormState();
  const params =
    typeof window !== 'undefined'
      ? new URL(window.location.href).searchParams
      : new URLSearchParams();

  const [hasStartedOrCompletedReload, setHasStartedOrCompletedReload] =
    useState<boolean>(false);

  useEffect(() => {
    const needsReload = !customer || !loanApplication;
    if (hasStartedOrCompletedReload) return;
    if (!needsReload) return;
    if (!survey) return;

    setHasStartedOrCompletedReload(true);

    const magicToken = params.get('token');
    if (magicToken) {
      dispatch({ type: 'magic.fetching' });

      client.getPersistanceValues(magicToken).then((persistanceValues) => {
        dispatch({ type: 'magic.resolved' });
        reload(persistanceValues, survey);
      });
    } else {
      const persistanceValues = PersistanceValuesSchema.safeParse({
        loanApplicationId: sessionStorage.getItem(
          SessionStorageKeys.LOAN_APPLICATION_ID
        ),
        JWT: sessionStorage.getItem(SessionStorageKeys.TOKEN),
      });

      if (persistanceValues.success) {
        reload(persistanceValues.data, survey);
      } else {
        dispatch({ type: 'form.ready' });
      }
    }
  }, [customer, hasStartedOrCompletedReload, loanApplication, params, survey]);
};

const reload = (persistanceValues: IPersistanceValues, survey: SurveyModel) => {
  dispatch({ type: 'form.initializing' });

  reloadState(persistanceValues)
    .then((reloadedData) => {
      injectData(survey, {
        ...reloadedData,
      });
      dispatch({ type: 'form.restored' });
    })
    .catch((err) => {
      captureException(err);
      console.error('Restoring form state failed!\n\n', err);
      dispatch({ type: 'form.ready' });
    });
};
