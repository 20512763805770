import { loan_application } from '@axo/shared/data-access/types';
import type {
  ChildrenFormGroupData,
  Step1Data as Step1DataDK,
  Step2Data as Step2DataDK,
  Step3Data as Step3DataDK,
  Step4Data as Step4DataDK,
  Step5Data as Step5DataDK,
  Step6Data as Step6DataDK,
  Step7Data as Step7DataDK,
  Step8Data as Step8DataDK,
  Step9Data as Step9DataDK,
} from '../../forms/Denmark/LoanApplication/FormData.dk.types';
import type { TStepFields } from './formFields.d';

const step1FieldsDK: TStepFields<Step1DataDK> = [
  {
    id: 'LoanApplication/AppliedAmount',
  },
  {
    id: 'LoanApplication/LoanDuration',
  },
  {
    id: 'Frontend/IsRefinancing',
    isOptional: true,
  },
  {
    id: 'LoanApplication/InitialPurpose',
    isOptional: true,
  },
  {
    id: 'LoanApplication/Purpose',
    parents: [
      {
        id: 'Frontend/IsRefinancing',
        values: [loan_application.Purpose.Other],
      },
    ],
  },
  {
    id: 'LoanApplication/OtherPurpose',
    isOptional: true,
    parents: [
      {
        id: 'LoanApplication/Purpose',
        values: [loan_application.Purpose.Other],
      },
    ],
  },
  {
    id: 'Customer/Email',
  },
  {
    id: 'Customer/MobilePhoneNumber',
  },
  {
    id: 'Person/SocialSecurityNumber',
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
  },
];

const step2FieldsDK: TStepFields<Step2DataDK> = [
  {
    id: 'Frontend/Flow',
  },
];

const step3FieldsDK: TStepFields<Step3DataDK> = [
  {
    id: 'LoanApplication/MaritalStatus',
  },
  {
    id: 'Frontend/PartnerNetIncomeMonthly',
    parents: [
      {
        id: 'LoanApplication/MaritalStatus',
        values: [
          loan_application.MaritalStatus.Cohabiting,
          loan_application.MaritalStatus.Married,
        ],
      },
    ],
  },
  {
    id: 'Frontend/AmountOfChildren',
  },
  {
    id: 'Child/0/Age' as keyof ChildrenFormGroupData,
    parents: [{ id: 'Frontend/AmountOfChildren', values: ['1'] }],
  },
  {
    id: 'Child/1/Age' as keyof ChildrenFormGroupData,
    parents: [{ id: 'Frontend/AmountOfChildren', values: ['2'] }],
  },
  {
    id: 'Child/2/Age' as keyof ChildrenFormGroupData,
    parents: [{ id: 'Frontend/AmountOfChildren', values: ['3'] }],
  },
  {
    id: 'Child/3/Age' as keyof ChildrenFormGroupData,
    parents: [{ id: 'Frontend/AmountOfChildren', values: ['4'] }],
  },
  { id: 'LoanApplication/HasCars' },
  {
    id: 'Frontend/HasLease',
    parents: [{ id: 'LoanApplication/HasCars', values: ['Yes'] }],
    isOptional: true,
  },
  {
    id: 'Car/0/TotalCostMonthly',
    parents: [{ id: 'Frontend/HasLease', values: ['Yes'] }],
  },
  {
    id: 'Frontend/IsDanish',
  },
  {
    id: 'LoanApplication/Citizenship',
    parents: [{ id: 'Frontend/IsDanish', values: [false] }],
  },
  {
    id: 'LoanApplication/ResidencePermit',
    parents: [
      { id: 'Frontend/IsDanish', values: [false] },
      { id: 'LoanApplication/Citizenship', values: ['EU', 'Other'] },
    ],
  },
  {
    id: 'LoanApplication/ResidencePermitType',
    parents: [
      { id: 'Frontend/IsDanish', values: [false] },
      {
        id: 'LoanApplication/ResidencePermit',
        values: [1, 2],
      },
    ],
  },
  {
    id: 'LoanApplication/ResidencePermitNumber',
    parents: [
      { id: 'Frontend/IsDanish', values: [false] },
      {
        id: 'LoanApplication/ResidencePermit',
        values: [1, 2],
      },
    ],
  },
  {
    id: 'LoanApplication/ResidencePermitExpiration',
    parents: [
      { id: 'Frontend/IsDanish', values: [false] },
      { id: 'LoanApplication/ResidencePermit', values: [1] },
    ],
  },
];

const step4FieldsDK: TStepFields<Step4DataDK> = [
  { id: 'LoanApplication/HousingType' },
  {
    id: 'Person/LivedAtAddressSince',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [loan_application.Residence.Rental],
      },
    ],
  },
  {
    id: 'LoanApplication/RentCostMonthly',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.Rental,
          loan_application.Residence.CooperativeApartment,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/OwnsHolidayHome',
  },
];

const step5FieldsDK: TStepFields<Step5DataDK> = [
  { id: 'Expense/TVPhoneInternet/Value' },
  {
    id: 'Expense/Transport/Value',
  },
  {
    id: 'Expense/Electricity/Value',
  },
  {
    id: 'Expense/Water/Value',
  },
  { id: 'Expense/Heating/Value' },
  {
    id: 'Expense/Insurance/Value',
  },
  {
    id: 'LoanApplication/LivingCostMonthly',
  },
];

const step6FieldsDK: TStepFields<Step6DataDK> = [
  {
    id: 'LoanApplication/EmploymentStatus',
  },
  {
    id: 'LoanApplication/EmploymentStatusSince',
  },
  {
    id: 'LoanApplication/CompanyOrganizationNumber',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [loan_application.EmploymentStatus.Entrepreneur],
      },
    ],
  },
  {
    id: 'Frontend/GrossIncomeMonthly',
  },
  {
    id: 'LoanApplication/NetIncomeMonthly',
  },
  {
    id: 'Frontend/HasExtraIncome',
  },
  {
    id: 'LoanApplication/ExtraIncomeMonthly',
    parents: [{ id: 'Frontend/HasExtraIncome', values: ['Yes'] }],
  },
  {
    id: 'LoanApplication/YearsAsBankCustomer',
  },
  {
    id: 'LoanApplication/HasDankort',
  },
  {
    id: 'LoanApplication/HasOtherPaymentCards',
  },
  {
    id: 'LoanApplication/AccountNumber',
  },
  { id: 'LoanApplication/RegistrationNumber' },
  {
    id: 'LoanApplication/HasUnemploymentFund',
  },
];

const step7FieldsDK: TStepFields<Step7DataDK> = [
  {
    id: 'Frontend/HasCurrentYearDebt',
    isOptional: true,
  },
  {
    id: 'LoanApplication/CurrentYearDebtOutstandingAmount',
    parents: [{ id: 'Frontend/HasCurrentYearDebt', values: [true] }],
  },
  {
    id: 'Frontend/HasDebtCollection',
    isOptional: true,
  },
  {
    id: 'LoanApplication/PaymentRemarks',
  },
  {
    id: 'LoanApplication/CreditStatusConsent',
    isOptional: true,
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
    parents: [
      { id: 'Ignore/MarketingConsent', values: [false] },
      { id: 'Ignore/ShowMarketingConsent', values: [true] },
    ],
  },
];

const step8FieldsDK: TStepFields<Step8DataDK> = [
  {
    id: 'Frontend/AddCoApplicant',
    isOptional: true,
  },
  {
    id: 'CoCustomer/Email',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
    isOptional: true,
  },
  {
    id: 'CoCustomer/MobilePhoneNumber',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
    isOptional: true,
  },
  {
    id: 'CoPerson/SocialSecurityNumber',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantEmploymentStatus',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantEmploymentStatusSince',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantCompanyOrganizationNumber',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [loan_application.EmploymentStatus.Entrepreneur],
      },
    ],
  },
  {
    id: 'Frontend/CoApplicantGrossIncomeMonthly',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'Frontend/CoApplicantNetIncomeMonthly',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      {
        id: 'LoanApplication/CoApplicantEmploymentStatus',
        values: [
          loan_application.EmploymentStatus.Permanent,
          loan_application.EmploymentStatus.Temporary,
          loan_application.EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'Frontend/CoApplicantIsDanish',
    isOptional: true,
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
  {
    id: 'LoanApplication/CoApplicantCitizenship',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'Frontend/CoApplicantIsDanish', values: [false] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantResidencePermit',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'Frontend/CoApplicantIsDanish', values: [false] },
      { id: 'LoanApplication/CoApplicantCitizenship', values: ['EU', 'Other'] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantResidencePermitType',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'Frontend/CoApplicantIsDanish', values: [false] },
      { id: 'LoanApplication/CoApplicantResidencePermit', values: [1, 2] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantResidencePermitNumber',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'Frontend/CoApplicantIsDanish', values: [false] },
      { id: 'LoanApplication/CoApplicantResidencePermit', values: [1, 2] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantResidencePermitExpiration',
    parents: [
      { id: 'Frontend/AddCoApplicant', values: [true] },
      { id: 'Frontend/CoApplicantIsDanish', values: [false] },
      { id: 'LoanApplication/CoApplicantResidencePermit', values: [1] },
    ],
  },
  {
    id: 'LoanApplication/CoApplicantHasUnemploymentFund',
    parents: [{ id: 'Frontend/AddCoApplicant', values: [true] }],
  },
];

const step9FieldsDK: TStepFields<Step9DataDK> = [
  {
    id: 'Frontend/MitID',
    isOptional: true,
  },
];

export {
  step1FieldsDK,
  step2FieldsDK,
  step3FieldsDK,
  step4FieldsDK,
  step5FieldsDK,
  step6FieldsDK,
  step7FieldsDK,
  step8FieldsDK,
  step9FieldsDK,
};
