import {
  EmploymentStatus,
  loan_application,
} from '@axo/shared/data-access/types';
import type {
  Step1Data as Step1DataFI,
  Step2Data as Step2DataFI,
  Step3Data as Step3DataFI,
  Step4Data as Step4DataFI,
  Step5Data as Step5DataFI,
  Step6Data as Step6DataFI,
} from '../../forms/Finland/FormData.fi.types';
import type { TStepFields } from './formFields.d';

const step1FieldsFI: TStepFields<Step1DataFI> = [
  {
    id: 'LoanApplication/AppliedAmount',
  },
  {
    id: 'LoanApplication/LoanDuration',
  },
  {
    id: 'LoanApplication/InitialPurpose',
    isOptional: true,
  },
  {
    id: 'LoanApplication/Purpose',
    parents: [
      {
        id: 'LoanApplication/InitialPurpose',
        values: [loan_application.Purpose.Other],
      },
    ],
    isOptional: true,
  },
  {
    id: 'Customer/Email',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Customer/MobilePhoneNumber',
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/MarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
  {
    id: 'Ignore/ShowMarketingConsent',
    isOptional: true,
    parents: [{ id: 'LoanApplication/InitialPurpose', values: [] }],
  },
];

const step2FieldsFI: TStepFields<Step2DataFI> = [
  {
    id: 'Person/SocialSecurityNumber',
  },
  {
    id: 'LoanApplication/MilitaryServiceCompleted',
    isOptional: true,
  },
  {
    id: 'Frontend/IsFinnish',
    isOptional: true,
  },
  {
    id: 'LoanApplication/Citizenship',
    parents: [{ id: 'Frontend/IsFinnish', values: ['No'] }],
  },
  {
    id: 'LoanApplication/LivedInMarketCountrySince',
    parents: [{ id: 'Frontend/IsFinnish', values: ['No'] }],
  },
];

const step3FieldsFI: TStepFields<Step3DataFI> = [
  {
    id: 'LoanApplication/EmploymentStatus',
  },
  {
    id: 'LoanApplication/Employer',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          EmploymentStatus.Permanent,
          EmploymentStatus.Temporary,
          EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/EmploymentStatusSince',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          EmploymentStatus.Permanent,
          EmploymentStatus.Temporary,
          EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/JobTitle',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [
          EmploymentStatus.Permanent,
          EmploymentStatus.Temporary,
          EmploymentStatus.Entrepreneur,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/EmploymentStatusUntil',
    parents: [
      {
        id: 'LoanApplication/EmploymentStatus',
        values: [EmploymentStatus.Temporary],
      },
    ],
  },
  {
    id: 'LoanApplication/Education',
  },
  {
    id: 'Debt/Student/OutstandingAmount',
    parents: [
      {
        id: 'LoanApplication/Education',
        values: [loan_application.Education.EducationHigherEducation],
      },
    ],
  },
  {
    id: 'Debt/Student/MonthlyDebtPayment',
    parents: [
      {
        id: 'Debt/Student/OutstandingAmount',
        values: [loan_application.Education.EducationHigherEducation],
      },
    ],
  },
  {
    id: 'Frontend/GrossIncomeMonthly',
  },
  {
    id: 'LoanApplication/NetIncomeMonthly',
  },
  {
    id: 'LoanApplication/ExtraIncomeMonthly',
    isOptional: true,
  },
];

const step4FieldsFI: TStepFields<Step4DataFI> = [
  {
    id: 'LoanApplication/MaritalStatus',
  },
  {
    id: 'Frontend/PartnerGrossIncomeMonthly',
    parents: [
      {
        id: 'LoanApplication/MaritalStatus',
        values: [
          loan_application.MaritalStatus.Married,
          loan_application.MaritalStatus.Cohabiting,
        ],
      },
    ],
  },
  {
    id: 'AmountOfChildren',
  },
  {
    id: 'LoanApplication/HousingType',
  },
  {
    id: 'LoanApplication/RentCostMonthly',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.Rental,
          loan_application.Residence.Cohabitant,
          loan_application.Residence.CompanyOwnedFlat,
          loan_application.Residence.PartnersHouse,
        ],
      },
    ],
  },
  {
    id: 'LoanApplication/OwnerAssociationCostMonthly',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.OwnHouse,
          loan_application.Residence.OwnApartment,
        ],
      },
    ],
  },
  {
    id: 'Debt/Housing/OutstandingAmount',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.OwnHouse,
          loan_application.Residence.OwnApartment,
          loan_application.Residence.Cohabitant,
        ],
      },
    ],
  },
  {
    id: 'Debt/Housing/MonthlyDebtPayment',
    parents: [
      {
        id: 'LoanApplication/HousingType',
        values: [
          loan_application.Residence.OwnHouse,
          loan_application.Residence.OwnApartment,
          loan_application.Residence.Cohabitant,
        ],
      },
      { id: 'Debt/Housing/OutstandingAmount', values: [] },
    ],
  },
  {
    id: 'LoanApplication/LivingCostMonthly',
  },
];

const step5FieldsFI: TStepFields<Step5DataFI> = [
  {
    id: 'Debt/CreditCard/OutstandingAmount',
  },
  {
    id: 'Debt/Personal/OutstandingAmount',
  },
  {
    id: 'Debt/Personal/MonthlyDebtPayment',
    parents: [{ id: 'Debt/Personal/OutstandingAmount', values: [] }],
  },
  {
    id: 'Debt/Car/OutstandingAmount',
  },
  {
    id: 'Debt/Car/MonthlyDebtPayment',
    parents: [{ id: 'Debt/Car/OutstandingAmount', values: [] }],
  },
  {
    id: 'LoanApplication/Purpose',
    parents: [
      {
        id: 'LoanApplication/InitialPurpose',
        values: [loan_application.Purpose.Other],
      },
      {
        id: 'Debt/Personal/RefinanceAmount',
        values: [0],
      },
    ],
  },
  {
    id: 'LoanApplication/AccountNumber',
  },
];

const step6FieldsFI: TStepFields<Step6DataFI> = [
  {
    id: 'LoanApplication/CreditStatusConsent',
  },
];
export {
  step1FieldsFI,
  step2FieldsFI,
  step3FieldsFI,
  step4FieldsFI,
  step5FieldsFI,
  step6FieldsFI,
};
