import {
  MARKETS,
  configureAxoFormV1,
  resetStore,
} from '@axo/form/feature/axo-form-v1';
import { AnalyticsProvider } from '@axo/shared/services/analytics';
import { LDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.scss';

configureAxoFormV1({
  API_URL: import.meta.env.VITE_API_URL,
  ENV: import.meta.env.VITE_ENV,
  ASSENTLY_URL: import.meta.env.VITE_ASSENTLY_URL,
  LAUNCH_DARKLY_CLIENT: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT,
  IS_WHITE_LABEL: true,
});

let root: Root | undefined;

/**
 * @kind White Label Wrapper
 * @summary Used by White Labels to load the Form
 * Should be replaced by the White-Label-Host _soon_
 */
const setup = () => {
  const id = 'axo-root';
  const element = document.getElementById(id);

  const market = MARKETS.find((m) => m === import.meta.env.VITE_MARKET_COUNTRY);

  if (!element)
    throw new Error(`Unable to mount react: no element with id "${id}"`);

  if (root) {
    root.unmount();
    resetStore();
  }

  root = createRoot(element);

  root.render(
    <React.StrictMode>
      <AnalyticsProvider enabledServices={['gtag']}>
        <BrowserRouter>
          <LDProvider
            clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT}
            context={{
              kind: 'form-v1',
              key: market || window?.location.hostname || 'dev',
            }}
            options={{
              logger: basicLogger({
                level: import.meta.env.DEV ? 'info' : 'warn',
              }),
            }}
            reactOptions={{
              useCamelCaseFlagKeys: false,
            }}
          >
            <App />
          </LDProvider>
        </BrowserRouter>
      </AnalyticsProvider>
    </React.StrictMode>
  );
};

const mo = new MutationObserver((records) => {
  return; // disabled until we can fix bug causing double forms
  // Run setup if any nodes with the id axo-form was added (including if any
  // children of the added node contains the id).
  if (
    records
      .flatMap((record) => Array.from(record.addedNodes))
      .flatMap((node) => (node as HTMLElement)?.querySelector?.('#axo-root'))
      .filter((node) => !!node).length > 0
  ) {
    setup();
  }
});

mo.observe(document, {
  subtree: true,
  childList: true,
});

setup();
