import { useAnalytics } from '@axo/shared/services/analytics';
import CheckboxUI from '@axo/shared/ui/input/lib/Checkbox/CheckboxUI';
import { classNames } from '@axo/shared/util/dom';
import { getFormattedAmountUI } from '@axo/shared/util/formatters';
import { useCallback, useMemo } from 'react';
import { useExtendedLoanOverviewContext } from '../../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import Slider from '../Slider/Slider';
import styles from './Detail.module.scss';

export type TDetailProps = {
  label: string;
  value: number;
  isEditMode?: boolean;
  maxValue: number;
  step?: number;
  isChecked: boolean;
  editType?: 'slider' | 'toggle' | 'steppedNumberInput';
  description?: string;
  onCheckboxChange: (newValue: boolean) => void;
  onValueChange: (newValue: number) => void;
};

const Detail = ({
  label,
  value = 0,
  isEditMode = false,
  editType = 'slider',
  maxValue,
  step = 500,
  isChecked,
  description,
  onCheckboxChange,
  onValueChange,
}: TDetailProps) => {
  const { currency } = useExtendedLoanOverviewContext();
  const { track } = useAnalytics();

  const valueLabel = `${getFormattedAmountUI(value)} ${currency}`;
  const isToggleMode = editType === 'toggle';
  const isSliderVisible = isEditMode && isChecked && editType === 'slider';

  const _handleCheckboxChange = useCallback(() => {
    if (editType === 'toggle') return;

    track({
      event: 'Debt Refinance Debt Toggled',
      params: { state: !isChecked, label, value },
    });

    onCheckboxChange(!isChecked);
  }, [onCheckboxChange, track, editType, label, value, isChecked]);

  const isValueLabelGreen = useMemo(
    () => !!(value > 0 && isChecked && isEditMode) || editType === 'toggle',
    [value, isChecked, isEditMode, editType]
  );

  const onDetailClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      if (editType !== 'toggle') return;

      track({
        event: 'Debt Refinance Debt Toggled',
        params: { state: !isChecked, label, value },
      });

      onCheckboxChange(!isChecked);
    },
    [editType, isChecked, onCheckboxChange, track, label, value]
  );

  const debtTitle = useMemo(() => {
    if (editType === 'toggle') {
      // return first two words
      return label.split(' ').slice(0, 2).join(' ');
    }
    return label;
  }, [editType, label]);

  return (
    <div
      className={classNames(
        isEditMode && styles.editModeContainer,
        editType === 'toggle' && styles.pointer
      )}
      onClick={isToggleMode ? onDetailClick : undefined}
    >
      <div className={styles.detail}>
        <div className={styles.textContainer}>
          <span className={styles.text}>{debtTitle}</span>
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
        <div className={styles.right}>
          <span
            className={classNames(
              styles.text,
              isValueLabelGreen && styles.textHighlight
            )}
          >
            {valueLabel}
          </span>
          <CheckboxUI
            name={label}
            onChange={_handleCheckboxChange}
            size="small"
            value={isChecked}
            state={isValueLabelGreen ? 'success' : 'neutral'}
            isUsingInternalState={false}
          />
        </div>
      </div>
      {isSliderVisible && (
        <Slider
          step={step}
          value={value}
          onChange={onValueChange}
          min={0}
          max={maxValue}
          currency={currency}
          className={styles.slider}
          trackHeight={8}
        />
      )}
    </div>
  );
};

export default Detail;
